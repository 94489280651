import React from 'react'
import PropTypes from 'prop-types'
import { graphql } from 'gatsby'
import Img from 'gatsby-image'
import { FormattedMessage } from 'react-intl'
import styled from 'styled-components'
import { Box, Flex, Heading, Text } from 'rebass/styled-components'

import Layout from '../components/layout'
import SEO from '../components/seo'
import { SectionWithObjects } from '../components/section'
import Container from '../components/container'
import Hero from '../components/hero'
import NextStep from '../components/next-step'
import OpeningHours from '../components/opening-hours'
import GlassEffect from '../components/glass-effect'
import Badge from '../components/badge'
import getTranslations from '../utils/getTranslations'

const RoundedImg = styled(Img)`
  border-radius: 4px;
  overflow: hidden;
`

const Content = (props) => (
  <Text
    sx={{
      color: 'white',
      '& p': { mb: 3 },
      '& a': { color: 'gold' },
      '& .button': { color: 'darkGray' },
    }}
    {...props}
  />
)

const OfferPage = ({ data, pageContext }) => {
  const { locale } = pageContext
  const {
    page,
    heroBackgroundImage,
    nextStepBackground,
    exkurzeImage,
    tapRentalImage,
  } = data
  const { title, acf, polylang_translations: translations } = page
  const {
    text_exkurzi: textExkurzi,
    text_pujcovny_vycepu: textPujcovnyVycepu,
    oteviraci_doba: openingHours,
    text_kam_dal: textKamDal,
    popisek_tlacitka_kam_dal: popisekTlacitkaKamDal,
    odkaz_kam_dal: odkazKamDal,
    popis_v_hero: popisVHero,
  } = acf

  return (
    <Layout
      locale={locale}
      pageTranslationLinks={getTranslations(locale, translations)}
    >
      <SEO title={title} />

      <Hero
        title={title}
        html={popisVHero || null}
        backgroundImage={heroBackgroundImage}
        backgroundPosition="50% 50%"
      >
        <Badge>
          <OpeningHours openingHours={openingHours} />
        </Badge>
      </Hero>

      <SectionWithObjects>
        <Container>
          <Flex
            sx={{
              flexDirection: ['column', 'row'],
              justifyContent: 'space-between',
            }}
          >
            <Box sx={{ width: ['100%', '48%'], mb: [4, 0] }}>
              <Heading as="h2" variant="sectionTitle">
                <FormattedMessage
                  id="offer.excursions"
                  defaultMessage="Exkurze"
                />
              </Heading>

              <Box mb={3}>
                <GlassEffect>
                  <RoundedImg
                    fluid={exkurzeImage.childImageSharp.fluid}
                    alt=""
                  />
                </GlassEffect>
              </Box>

              <Content dangerouslySetInnerHTML={{ __html: textExkurzi }} />
            </Box>

            <Box sx={{ width: ['100%', '48%'] }}>
              <Heading as="h2" variant="sectionTitle">
                <FormattedMessage
                  id="offer.tapRental"
                  defaultMessage="Půjčovna výčepů"
                />
              </Heading>

              <Box mb={3}>
                <GlassEffect>
                  <RoundedImg
                    fluid={tapRentalImage.childImageSharp.fluid}
                    alt=""
                  />
                </GlassEffect>
              </Box>

              <Content
                dangerouslySetInnerHTML={{ __html: textPujcovnyVycepu }}
              />
            </Box>
          </Flex>
        </Container>
      </SectionWithObjects>

      <NextStep
        title={textKamDal || ''}
        link={odkazKamDal}
        label={popisekTlacitkaKamDal}
        backgroundImage={nextStepBackground}
      />
    </Layout>
  )
}

OfferPage.propTypes = {
  data: PropTypes.object.isRequired,
  pageContext: PropTypes.object.isRequired,
}

export default OfferPage

export const pageQuery = graphql`
  query OfferPageQuery($key: String!) {
    page: wordpressPage(slug: { eq: $key }) {
      ...PageData
      ...PageTranslations
      acf {
        ...NextStep
        popis_v_hero
        text_exkurzi
        text_pujcovny_vycepu
        oteviraci_doba
      }
    }
    heroBackgroundImage: file(relativePath: { eq: "nabizime.jpg" }) {
      childImageSharp {
        fluid(quality: 80, maxWidth: 1920) {
          ...GatsbyImageSharpFluid_withWebp
        }
      }
    }
    nextStepBackground: file(relativePath: { eq: "nabizime-next-step.jpg" }) {
      childImageSharp {
        fluid(quality: 80, maxWidth: 1920) {
          ...GatsbyImageSharpFluid_withWebp
        }
      }
    }
    exkurzeImage: file(relativePath: { eq: "exkurze.jpg" }) {
      childImageSharp {
        fluid(quality: 80, maxWidth: 640) {
          ...GatsbyImageSharpFluid_withWebp
        }
      }
    }
    tapRentalImage: file(relativePath: { eq: "pujcovna-vycepu.jpg" }) {
      childImageSharp {
        fluid(quality: 80, maxWidth: 640) {
          ...GatsbyImageSharpFluid_withWebp
        }
      }
    }
  }
`
